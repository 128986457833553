import React, { useMemo } from "react";
import Sidebar from "./components/Sidebar";
import Header from "./components/Header";
import Content from "./components/Content";
import { LayoutContextType } from "./LayoutContextType";
import { useLayout } from "./useLayout";
import HeaderTitle from "./components/HeadeTitle";

export const LayoutContext = React.createContext<LayoutContextType>({
  headerTitle: "Quality Management",
  headerBackButton: false,
  isHeaderLoading: false,
  setHeaderTitle: (newTitle) => {},
  setHeaderBackButton: (value) => {},
  setIsHeaderLoading: (isLoading) => {},
});

const Layout: React.FC = ({ children }) => {
  const { headerTitle, headerBackButton, isHeaderLoading } = useLayout();

  const finalHeaderTitle = useMemo(() => {
    if (headerBackButton) {
      return <HeaderTitle text={headerTitle} withBackButton />;
    }

    return <HeaderTitle text={headerTitle} />;
  }, [headerTitle, headerBackButton]);

  return (
    <>
      <Header title={finalHeaderTitle} isTitleLoading={isHeaderLoading} />
      <Sidebar />
      <Content>{children}</Content>
    </>
  );
};

export default Layout;
