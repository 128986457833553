import { createGlobalStyle } from "styled-components";

const GlobalStyle = createGlobalStyle`
  body,
  html {
    margin: 0;
    padding: 0;

    background-color: #e5e5e5;
  }

  * {
    box-sizing: border-box;
    font-family: ${({ theme }) => theme.fontFamily.primary}, sans-serif;
  }

  // remove up-down arrow input number
  input::-webkit-outer-spin-button,
  input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }

  // remove up-down arrow input number - firefox
  input[type=number] {
    -moz-appearance: textfield;
  }


  // react collapse
  .ReactCollapse--collapse {
    min-width: 100%;
    transition: height 0.3s;
  }
`;

export default GlobalStyle;
