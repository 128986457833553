import React from "react";
import Image from "next/image";
import styled from "styled-components";
import FlexDiv from "../FlexDiv";

const ImageWrapper = styled.div`
  position: relative;
  width: 48px;
  height: 48px;
  border-radius: 50%;
`;

const Username = styled.p`
  margin: 0;
  font-size: 16px;
  font-weight: 600;
`;

const Role = styled.p`
  margin: 0;
  font-size: 14px;
`;

type AvatarProps = {
  imageUrl: string;
  name: string;
  role: string;
};

const Avatar = ({ imageUrl, name, role }: AvatarProps) => {
  return (
    <FlexDiv alignItems="center" gap="10px">
      <ImageWrapper>
        <Image src={imageUrl} layout="fill" objectFit="cover" alt={name} />
      </ImageWrapper>
      <FlexDiv
        justifyContent="center"
        alignItems="flex-start"
        flexDirection="column"
      >
        <Username>{name}</Username>
        <Role>{role}</Role>
      </FlexDiv>
    </FlexDiv>
  );
};

export default Avatar;
