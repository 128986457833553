import React, { useCallback, useState } from "react";
import { LayoutContext } from "./index";

const LayoutProvider: React.FC = ({ children }) => {
  const [headerTitle, setHeaderTitle] = useState<string>("Quality Management");
  const [isHeaderLoading, setIsHeaderLoading] = useState(false);
  const [headerBackButton, setHeaderBackButton] = useState<boolean>(false);

  const _setIsHeaderLoading = useCallback((isLoading?: boolean) => {
    if (isLoading != null) {
      setIsHeaderLoading(isLoading);
    } else {
      setIsHeaderLoading((prev) => !prev);
    }
  }, []);

  return (
    <LayoutContext.Provider
      value={{
        headerTitle,
        headerBackButton,
        isHeaderLoading,
        setHeaderTitle,
        setHeaderBackButton,
        setIsHeaderLoading: _setIsHeaderLoading,
      }}
    >
      {children}
    </LayoutContext.Provider>
  );
};

export default LayoutProvider;
