import '@fontsource/poppins/400.css';
import '@fontsource/poppins/400-italic.css';
import '@fontsource/poppins/500.css';
import '@fontsource/poppins/500-italic.css';
import '@fontsource/poppins/600.css';
import '@fontsource/poppins/600-italic.css';
import '@fontsource/poppins/700.css';
import '@fontsource/poppins/700-italic.css';

import 'react-toastify/dist/ReactToastify.min.css';

import type { AppProps } from 'next/app';

import GlobalStyle from '../GlobalStyle';
import { ThemeProvider } from 'styled-components';
import { theme } from '../theme';

import { SessionProvider } from 'next-auth/react';
import { useState } from 'react';
import { QueryClient, QueryClientProvider } from 'react-query';
import { DndProvider } from 'react-dnd';
import { HTML5Backend } from 'react-dnd-html5-backend';
import { ToastContainer } from 'react-toastify';
import LayoutProvider from '../features/layout/LayoutProvider';

function MyApp({Component, pageProps: {session, ...pageProps}}: AppProps) {
    const [queryClient] = useState(() => new QueryClient());
    
    return <>
        <QueryClientProvider client={queryClient}>
            <SessionProvider session={session}>
                <LayoutProvider>
                    <ThemeProvider theme={theme}>
                        <DndProvider backend={HTML5Backend}>
                            <GlobalStyle/>
                            <Component {...pageProps} />
                            <ToastContainer/>
                        </DndProvider>
                    </ThemeProvider>
                </LayoutProvider>
            </SessionProvider>
        </QueryClientProvider>
    </>;
}

export default MyApp;
