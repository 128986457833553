import React from 'react';
import styled from 'styled-components';
import FlexDiv from '../FlexDiv';

const Wrapper = styled(FlexDiv)`
  padding: 8px;
  border-radius: ${({theme}) => theme.borderRadius.md};

  background-color: ${({theme}) => theme.colors.primary};
  color: #fff;

  font-weight: 600;
  font-size: 18px;
  line-height: 1em;
`;

const Logo = () => {
    return (
        <Wrapper justifyContent="center" alignItems="center">
            QM
        </Wrapper>
    );
};

export default Logo;
