import React from 'react';
import Image from 'next/image';
import styled from 'styled-components';
import FlexDiv from '../../../../components/FlexDiv';
import Hr from '../../../../components/Hr';
import Navigation from '../../../navigation';

const Wrapper = styled.section`
  background-color: #fff;
  width: 100%;
  height: calc(100% - 80px);
`;

const SidebarBottom = () => {
    return (
        <Wrapper>
            <Navigation/>
        </Wrapper>
    );
};

export default SidebarBottom;
