import React from 'react';
import styled from 'styled-components';
import SidebarUpper from './SidebarUpper';
import SidebarBottom from './SidebarBottom';

const Aside = styled.aside`
  position: fixed;

  left: 0;
  top: 0;

  width: 336px;
  height: 100vh;
`;

const Sidebar = () => {
    return (
        <Aside>
            <SidebarUpper/>
            <SidebarBottom/>
        </Aside>
    );
};

export default Sidebar;
