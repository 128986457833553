import React from 'react';
import styled from 'styled-components';

const Flex = styled.div`
  display: flex;
`;

type FlexDivProps = {
    justifyContent?: 'flex-start' | 'flex-end' | 'space-between' | 'space-around' | 'space-evenly' | 'stretch' | 'center';
    alignItems?: 'flex-start' | 'flex-end' | 'space-between' | 'space-around' | 'space-evenly' | 'stretch' | 'center';
    flexWrap?: 'wrap' | 'nowrap' | 'wrap-reverse';
    flexDirection?: 'row' | 'row-reverse' | 'column' | 'column-reverse' | 'inherit' | 'initial' | 'unset',
    gap?: number | string;
} & React.ComponentPropsWithRef<'div'>

const FlexDiv = React.forwardRef<HTMLDivElement, FlexDivProps>(({
                                                                    children,
                                                                    justifyContent = 'flex-start',
                                                                    alignItems = 'stretch',
                                                                    flexWrap = 'wrap',
                                                                    flexDirection = 'row',
                                                                    gap = 0,
                                                                    style,
                                                                    ...rest
                                                                }, ref) => {
    return (
        <Flex style={{
            ...{
                justifyContent: justifyContent,
                alignItems: alignItems,
                flexWrap: flexWrap,
                flexDirection: flexDirection,
                gap: gap
            }, ...style
        }} ref={ref} {...rest}>
            {children}
        </Flex>
    );
});

FlexDiv.displayName = 'Flex Div';

export default FlexDiv;
