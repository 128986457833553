import React, { useCallback } from 'react';
import styled from 'styled-components';
import FlexDiv from '../../../../components/FlexDiv';
import { useRouter } from 'next/router';

const Title = styled.h1`
  margin: 0;
  font-weight: 600;
  font-size: 20px;
`;

const BackButtonAnchor = styled.a`
  display: inline-block;

  line-height: 0;

  img {
    width: 16px;
    height: auto;
  }
`;

type HeaderTitleProps = {
    text: string;
    withBackButton?: boolean;
}

const HeaderTitle = ({text, withBackButton = false}: HeaderTitleProps) => {
    const router = useRouter();
    
    const onBack = useCallback(() => {
        router.back();
    }, []);
    
    if (withBackButton)
        return (
            <FlexDiv gap="20px" alignItems="center">
                {/* eslint-disable-next-line @next/next/no-img-element */}
                <BackButtonAnchor onClick={onBack}><img src="/header-back-icon.png" alt="Back"/></BackButtonAnchor>
                
                <Title>
                    {text}
                </Title>
            </FlexDiv>
        
        );
    
    return (
        <Title>
            {text}
        </Title>
    );
};

export default HeaderTitle;
