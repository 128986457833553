import React from 'react';

const FullWidthContainer: React.FC = ({children}) => {
    return (
        <div style={{margin: '0 -32px'}}>
            {children}
        </div>
    );
};

export default FullWidthContainer;
