import React from 'react';
import styled from 'styled-components';
import Logo from '../../../../components/Logo';
import FlexDiv from '../../../../components/FlexDiv';

const Wrapper = styled(FlexDiv)`
  background-color: #000;
  height: 80px;
  width: 100%;
`;

const Title = styled.h1`
  margin: 0;
  font-size: 16px;
  font-weight: 400;
  color: #fff;
`;

const SidebarUpper = () => {
    return (
        <Wrapper alignItems="center" justifyContent="center">
            <FlexDiv alignItems="center" gap="10px">
                <Logo/>
                <Title>Quality Management</Title>
            </FlexDiv>
        </Wrapper>
    );
};

export default SidebarUpper;
