import { DefaultTheme } from 'styled-components';

const theme: DefaultTheme = {
    borderRadius: {
        sm: '4px',
        md: '8px'
    },
    colors: {
        primary: '#5B46F1',
        secondary: '#EFECFE',
        dark: '#000',
        white: '#fff',
        grey: '#f0f0f0'
    },
    fontFamily: {
        primary: `'Poppins', sans-serif`,
        secondary: `serif`
    }
};

export { theme };
