import React from 'react';
import styled from 'styled-components';

const Outer = styled.div`
  width: 100%;
  padding-left: 360px;
  padding-top: 104px;
  padding-right: 24px;
`;

const Main = styled.main`
  width: 100%;
  padding: 16px 32px;

  background-color: #fff;
  border-radius: ${({theme}) => theme.borderRadius.md};
`;

const Content: React.FC = ({children}) => {
    return (
        <Outer>
            <Main>
                {children}
            </Main>
        </Outer>
    );
};

export { default as FullWidthContainer } from './FullWidthContainer';

export default Content;
