import React, { ReactNode } from "react";
import styled from "styled-components";
import FlexDiv from "../../../../components/FlexDiv";
import Avatar from "../../../../components/Avatar";

const Wrapper = styled.header`
  position: fixed;
  top: 0;
  left: 336px;

  padding: 16px;
  width: calc(100% - 336px);
  height: 80px;

  background-color: #fff;
`;

const ImageButton = styled.button`
  background-color: transparent;
  border: none;
  outline: none;
  padding: 0;

  cursor: pointer;

  img {
    width: 100%;
  }
`;

const Bell = styled(ImageButton)`
  width: 18px;
`;

const CustomerSupport = styled(ImageButton)`
  width: 20px;
`;

const CaretDown = styled(ImageButton)`
  width: 15px;
`;

type HeaderProps = {
  title?: ReactNode;
  isTitleLoading?: boolean;
};

const Header = ({
  title = "Quality Management",
  isTitleLoading = false,
}: HeaderProps) => {
  return (
    <Wrapper>
      <FlexDiv justifyContent="space-between" alignItems="center">
        {!isTitleLoading && title}

        <FlexDiv alignItems="center" justifyContent="flex-end" gap="30px">
          <Bell>
            <img src="/bell.png" alt="Bell" />
          </Bell>
          <CustomerSupport>
            <img src="/customer-support.png" alt="Customer Support" />
          </CustomerSupport>
          <Avatar
            imageUrl="/profile-picture.png"
            name="Chatering Emerson"
            role="Project Manager"
          />
          <CaretDown>
            <img src="/caret-down.png" alt="Caret Down" />
          </CaretDown>
        </FlexDiv>
      </FlexDiv>
    </Wrapper>
  );
};

export default Header;
