import React from 'react';
import styled from 'styled-components';

const Line = styled.div`
  position: absolute;
  bottom: 1px;
  left: 50%;
  transform: translate(-50%, 0);

  width: 70%;
  height: 1px;

  background-color: #F0F0F0;
`;

const Hr = () => {
    return (
        <Line/>
    );
};

export default Hr;
