import React from 'react';
import FlexDiv from '../../components/FlexDiv';
import NavigationItem from './NavigationItem';
import { useRouter } from 'next/router';

const navigations = [
    {
        label: 'Project',
        baseUrl: '/projects',
        url: '/projects',
        icon: 'project.png',
        activeIcon: 'project.png'
    },
    {
        label: 'Bill Job',
        baseUrl: '/bill-job',
        url: '/bill-job',
        icon: 'bill-job.png',
        activeIcon: 'bill-job.png'
    },
    {
        label: 'Master Data',
        baseUrl: '/master-data',
        url: '/master-data',
        icon: 'master-data.png',
        activeIcon: 'master-data.png'
    }];

const Navigation = () => {
    const {pathname, push} = useRouter();
    
    const mapNavigationItem = () => {
        return navigations.map(
            navi => <NavigationItem key={navi.label} onClick={() => push(navi.url)} label={navi.label}
                                    selected={pathname.includes(navi.baseUrl)} icon={navi.icon}
                                    activeIcon={navi.activeIcon}/>);
    };
    
    return (
        <FlexDiv flexDirection="column" alignItems="stretch">
            {mapNavigationItem()}
        </FlexDiv>
    );
};

export default Navigation;
