import React from 'react';
import styled from 'styled-components';
import Hr from '../../../components/Hr';
import FlexDiv from '../../../components/FlexDiv';

const Wrapper = styled(FlexDiv)`
  position: relative;

  cursor: pointer;
  height: 80px;
`;

const InnerWrapper = styled(FlexDiv)`
  width: calc(100% - 64px);
  margin: 0 auto;
`;

const Icon = styled.img`
  width: 32px;
  height: 32px;
  object-fit: fill;
`;

const Label = styled.p`
  margin: 0;
  font-weight: 600;
  font-size: 14px;
`;

const NavArrow = styled.img`
  position: absolute;
  width: 8px;

  top: 50%;
  right: 32px;

  transform: translateY(-50%);
`;

const SelectedIndicator = styled.div`
  position: absolute;
  left: 0;
  top: 50%;
  transform: translateY(-50%);

  width: 3px;
  height: 80%;
  background-color: ${({theme}) => theme.colors.primary};
`;


type NavigationItemProps = {
    label: string;
    onClick: () => any;
    selected?: boolean;
    icon: string;
    activeIcon: string;
}

const NavigationItem = ({label, onClick, selected = false, icon, activeIcon}: NavigationItemProps) => {
    return (
        <Wrapper justifyContent="center" alignItems="center" onClick={onClick}>
            {
                selected &&
                <SelectedIndicator/>
            }
            
            <InnerWrapper alignItems="center" gap="24px">
                {
                    selected === false &&
                    <Icon src={`/${icon}`} alt="Project"/>
                }
                
                {
                    selected &&
                    <Icon src={`/${activeIcon}`} alt="Project"/>
                }
                
                <Label>{label}</Label>
            </InnerWrapper>
            
            <NavArrow src="/nav-arrow.png"/>
            <Hr/>
        </Wrapper>
    );
};

export default NavigationItem;
